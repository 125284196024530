// *** Login Component ****
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { ApiHelper } from '../../helpers/ApiHelper';
import "../../assets/css/login.css";
import "../../index.css";
import logo from "../../assets/img/logo192.png";
import Spinner from "../../components/Spinner";
import PopUp from "../../components/PopUp";
const Login = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [message, setMessage] = useState("");
  const handleLoginSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (email !== "" && password !== "") {
      setError(false);
      const formData = {
        email: email,
        password: password,
      };
      console.log("Submitted:", formData);
      await ApiHelper.post(API.login, formData)
                .then((resData) => {
                  setLoader(false)
                  console.log('response',resData);
                  if(resData.data.status === true){
                    setMessage('Login Successfully')
                    setOpenPopUp(true);
                    setTimeout(function () {
                        setOpenPopUp(false);
                    }, 1000);
                    navigate("/dashboard");
                  }else{
                    setMessage('The given data was invalid.')
                    setOpenPopUp(true);
                    setTimeout(function () {
                        setOpenPopUp(false);
                    }, 1000);

                  }
                    
                  
                  
                    
                })
                .catch((err) => {
                    setLoader(false);
                    setMessage("Error !!! Please try again");
                    console.log(err, 'err');
                }); 
        
    } else {
      setLoader(false);
      setError(true);
    }
  };
  return (
    <div className="centered loginWraper">
      <div className="Login_container">
        <div className="card">
          <div className="d-flex loginBoxs">
            <div className="col-md-6 loginBackground">
              <div className="logoBg">
                <img src={logo} className="logoIc" alt="FoodiePlanet" />{" "}
                <h4 className="title">Search Gurbani </h4>
              </div>
            </div>
            <div className="col-md-6 formBg">
              <form>
                <h1 className="Login_heading heading-primary">Admin Login</h1>
                <div className="form-group ">
                  <label className="label">Admin Email</label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control"
                    placeholder=" "
                  />
                  {email === "" && error ? <p style={{ color: "red", fontSize: '12px' }}>Enter a valid email</p> : null}
                </div>
                <div className="form-group">
                  <label className="label">Password</label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="form-control"
                    placeholder=" "
                  />
                  {password === "" && error ? <p style={{ color: "red", fontSize: '12px' }}>Enter password</p> : null}
                </div>
                {/* <p className="forgot-password text-right ">
                  <Link to="/forgot-password" className="frgt">
                    Forgot Password?
                  </Link>
                </p> */}
                <button
                  onClick={handleLoginSubmit}
                  disabled={error && (email === "" || password === "")}
                  type="submit"
                  className="btn buttonSub"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
      {loader && <Spinner />}
      {openPopUp && <PopUp message={message} />} 
    </div>
  );
};
export default Login;
